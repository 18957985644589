.sideBar{
    display: flex;
    flex-direction: column;
    width: 300px;
    background: #fff;
    padding: 20px 0;
    height: 100vh;
}

.menunoi{
    display: none;
}

.chatItem{
    cursor: pointer;
    padding: 10px;
    margin-left: 20px;
    border-radius: 8px;
    margin-right: 20px;
    width: 100%;
    display: flex;
    gap: 10px;
    height: 40px;
    
}

.chatItem:hover{
    background: #EFEFEF!important;

}
.chatContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 20px;
}


.chatHistory {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
    // background: #f7f7f7;
  }
  
  .chatMessage {
    margin: 8px 0;
    padding: 10px;
    border-radius: 8px;
    max-width: 95%;
  }
  
  .userMessage {
    background:  #E9E9EB;
    color: #000;
    // margin-left: auto;
    width: fit-content;
  }
  
  .userMessage p {
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .agentMessage {
    width: fit-content;
    margin: 0;
  }

  .agentMessage p {
    margin: 0;
  }

  .senderName {
    font-size: 12px;
    font-weight: bold;
    color: #666;
    margin-bottom: 3px;
    display: flex;
  }
  
//   .userName {
//     justify-content: flex-end;
//   }
  
//   .agentName {
//     justify-content: flex-start;
//   }
  
  .chat-bubble.user {
    background-color: #007bff;
    color: white;
    align-self: flex-end;
  }
  
  .chat-bubble.agent {
    background-color: #f1f1f1;
    color: black;
    align-self: flex-start;
  }
  
  /* Optionally, you can add a loading spinner here */
  .loader {
    width: 20px;
    aspect-ratio: 2;
    --_g: no-repeat radial-gradient(circle closest-side, #888 90%, #0000);
    background:
      var(--_g) 0% 50%,
      var(--_g) 50% 50%,
      var(--_g) 100% 50%;
    background-size: calc(100%/3) 50%;
    animation: l3 1s infinite linear;
  }
  
  @keyframes l3 {
    20% {
      background-position: 0% 0%, 50% 50%, 100% 50%
    }
  
    40% {
      background-position: 0% 100%, 50% 0%, 100% 50%
    }
  
    60% {
      background-position: 0% 50%, 50% 100%, 100% 0%
    }
  
    80% {
      background-position: 0% 50%, 50% 50%, 100% 100%
    }
  }

  .chatInputBox{
    padding: 16px;
    // border: 1px solid #e8e8e8;
    border-radius: 10px;
    margin-bottom: 20px;
    background: #fff;
  }

  .chatInput {
    display: flex;
    // padding: 16px;
    // border: 1px solid #e8e8e8;
    // border-radius: 10px;
    // margin-bottom: 20px;
    // background: #fff;
    align-items: center;
    position: relative;
  
    textarea {
      flex: 1;
      padding: 8px;
      margin-right: 8px;
      border: 1px solid #d9d9d9;
      border-radius: 7px;
      background-color: #F9FAFB;
  
      .ant-input css-dev-only-do-not-override-qnu6hi ant-input-outlined{
        border: none !important;
      }
    }
  
    button {
      padding: 8px 16px;
      background: #F9FAFB;
      color: #9DA4B0;
      border: none;
      border-radius: 7px;
      cursor: pointer;
  
      &:hover {
        background: #40a9ff;
      }
  
      .ant-btn {
        height: 100% !important;
        width: 40px !important;
      }
  
  
    }
  
  }

  .newChatBox{
    margin: auto ;
    // background: #ededed;    
    // border-radius: 20px;
    padding: 20px 40px;
    max-width: 700px;
    // border: 1px solid #d9d9d9;
    // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }

  .newChatInput {
    display: flex;
    padding: 16px;
    // border-top: 1px solid #e8e8e8;
    // min-width: calc(100vw - 1000px);
    max-width: 700px;
    align-items: stretch;
  
    textarea {
      flex: 1;
      padding: 8px;
      margin-right: 8px;
      border: 1px solid #d9d9d9;
      border-radius: 7px;
      background-color: #F9FAFB;
  
      .ant-input css-dev-only-do-not-override-qnu6hi ant-input-outlined{
        border: none !important;
      }
    }
  
    button {
      padding: 8px 16px;
      background: #F9FAFB;
      color: #9DA4B0;
      border: none;
      border-radius: 7px;
      cursor: pointer;
  
      &:hover {
        background: #40a9ff;
      }
  
      .ant-btn {
        height: 100% !important;
        width: 40px !important;
      }
  
  
    }
  
  }

.newChat, .searchChat{
    padding: 20px 10px;
    height: 29px;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 15px;
}

.newChat:hover{
    background: #EFEFEF;
}

.searchChat:hover{
    background: rgb(236 236 236);
}

.logo{
    width: 60px;
    height: 60px;
}

.speechToText{
    display: flex;
    align-items: center;
    height: fit-content;
}

.speechToText div {
    position: unset !important;
}

.newChatInput button span, .chatInput button span{
    display: flex !important;
}

@media (max-width: 767px) {
    .chatContainer{
        height: calc(100vh - 60px);
    }
}