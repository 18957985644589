* {
    box-sizing: border-box;
}

.mainHeader{
    height: 84px;
    color: #121927;
    font-size: 20px;
    font-weight: 500;
    line-height: 42px;
    text-align: left;
    padding: 20px 50px;
    background: #fff;
    border-bottom: 1px solid #DCDCDC;
    display: flex;
    justify-content: space-between;
}

.tableData{
    padding: 20px;
}

.id{
    color: #121927;
}

.id:hover{
    color: #1677ff;
}

.BtnCreate{
    background: #E6F4FF;
    border-radius: 30px;
    color: #000;
    padding: 2px 15px;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}