.chatModal {
  :global(.ant-modal-body) {
    padding: 0;
  }
}

.chatContainer {
  display: flex;
  flex-direction: column;
  height: 500px;
}

.chatHistory {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  // background: #f7f7f7;
}

.chatMessage {
  margin: 8px 0;
  padding: 10px;
  border-radius: 8px;
  max-width: 70%;
}

.userMessage {
  background: #1677FF;
  color: white;
  margin-left: auto;
  width: fit-content;
}

.userMessage p {
  margin-top: 0;
  margin-bottom: 0;
}

.agentMessage {
  background: #E9E9EB;
  // border: 1px solid #e8e8e8;
  width: fit-content;
}

.chatInput {
  display: flex;
  padding: 16px;
  border-top: 1px solid #e8e8e8;

  textarea {
    flex: 1;
    padding: 8px;
    margin-right: 8px;
    border: 1px solid #d9d9d9;
    border-radius: 7px;
    background-color: #F9FAFB;

    // &:where(.css-dev-only-do-not-override-qnu6hi).ant-input-outlined:focus,
    // :where(.css-dev-only-do-not-override-qnu6hi).ant-input-outlined:focus-within {
    //   border-color: none !important;
    //   background-color: #F9FAFB !important;
    // }
    // .input:focus {
    //   outline: none !important;
    //   border:1px solid red;
    //   box-shadow: 0 0 10px #719ECE;
    // }
    .ant-input css-dev-only-do-not-override-qnu6hi ant-input-outlined{
      border: none !important;
    }
  }

  button {
    padding: 8px 16px;
    background: #F9FAFB;
    color: #9DA4B0;
    border: none;
    border-radius: 7px;
    cursor: pointer;

    &:hover {
      background: #40a9ff;
    }

    .ant-btn {
      height: 100% !important;
      width: 40px !important;
    }


  }

  // .sendButton {
  //   display: flex;
  //   align-items: center;
  //   gap: 8px;
  //   height: fit-content;
  //   align-self: flex-end;
  //   padding: 8px 16px;
  //   margin-left: 8px;
  //   white-space: nowrap;
  // }

}

.sendIcon {
  // margin-right: 4px;
  // transform: rotate(-40deg);
}

.loadingText {
  font-style: italic;
  color: #888;
}

.senderName {
  font-size: 12px;
  font-weight: bold;
  color: #666;
  margin-bottom: 3px;
  display: flex;
}

.userName {
  justify-content: flex-end;
}

.agentName {
  justify-content: flex-start;
}

.chat-bubble.user {
  background-color: #007bff;
  color: white;
  align-self: flex-end;
}

.chat-bubble.agent {
  background-color: #f1f1f1;
  color: black;
  align-self: flex-start;
}

/* Optionally, you can add a loading spinner here */
.loader {
  width: 20px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side, #888 90%, #0000);
  background:
    var(--_g) 0% 50%,
    var(--_g) 50% 50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 50%;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%
  }

  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%
  }

  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%
  }

  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%
  }
}